import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { DualAxes } from '@ant-design/plots';
import _ from 'lodash';

export const SalesByCountryChart = ({ type, searchCondition, searchCountry, chartType }) => {
    const [isProgress, setProgress] = useState(false);
    const [dataSource, setDataSource] = useState([]);

    useEffect(() => {
        setDataSource([]);
        //if (!_.isEmpty(searchCountry)) {
            getList();
        //}        
    }, [searchCountry]);

    const getList = async (e) => {
        //if (Object.keys(searchCondition).length !== 0 && searchCondition.dateString.length > 0) {
            //console.log('here', searchCondition);
            setProgress(true);
        try {
            var res;
            console.log('chartType', chartType);
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(encodeURI(searchCountry))
            };
            if (chartType == 'country') {
                res = await fetch("/dataupload/GetChartsDataByCountry/" + searchCondition.dateString[0] + "/" + searchCondition.dateString[1], requestOptions);
            } else if (chartType == 'supp_company') {
                res = await fetch("/dataupload/GetChartsDataBySuppCompany/" + searchCondition.dateString[0] + "/" + searchCondition.dateString[1], requestOptions);
            } else {
                res = await fetch("/dataupload/GetChartsDataByCompany/" + searchCondition.dateString[0] + "/" + searchCondition.dateString[1], requestOptions);
            }            

            if (res.ok) {
                
                res.json().then(v => {
                    setDataSource(v);
                    setProgress(false);
                }).then(() => {
                    console.log(dataSource);
                });

            } else {
                
                setProgress(false);
                alert('Error occurred');
            }
        } catch (ex) {
            setProgress(false);
            console.log("error", ex.toString());
        } finally {
            console.log("test");
        }
        //}
    }



    const config = {
        data: [dataSource, dataSource],
        xField: 'YearMth',
        yField: ['total_qty', 'total_amt'],
        yAxis: {
            // 格式化左坐标轴
            total_qty: {
                min: 0,
                label: {
                    formatter: (val) => `${val}`,
                },
            },
            total_amt: {
                min: 0,
                label: {
                    formatter: (val) => `${val}`,
                },
            },
            // 隐藏右坐标轴
            //count: false,
        },
        geometryOptions: [
            {
                geometry: 'column',
            },
            {
                geometry: 'line',
                lineStyle: {
                    lineWidth: 2,
                },
            },
        ],
    };
    return (
        <>
            <h3>Sales for {searchCountry}</h3>
            <DualAxes {...config} />
        </>
        
    );
};
