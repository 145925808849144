import React, { Component, useState, useEffect, useRef } from 'react';
import { SearchOutlined, BarChartOutlined } from '@ant-design/icons';
import { Col, Row, Button, Input, Space, Table } from 'antd';
import Highlighter from 'react-highlight-words';
import _ from 'lodash';

export const SalesByCompanyList = ({ searchCondition, setSearchCountry, setOpen, setChartType }) => {
    const [isProgress, setProgress] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        console.log('handleSearch', selectedKeys, confirm, dataIndex);
        confirm();
        //setSearchText(selectedKeys[0]);
        //setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex, type = 'text') => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? formatCell(text.toString(), type) : ''}
                />
            ) : (
                formatCell(text.toString(), type)
            ),
    });

    const formatCell = (text, type) => {
        if (type.toLowerCase() == 'decimal') {
            return _.round(text, 2).toLocaleString('en-US');
        }
        return text;
    }

    const columns = [
        {
            title: 'Import Company',
            dataIndex: 'Import_Company',
            key: 'Import_Company',
            ...getColumnSearchProps('Import_Company'),
        }, {
            title: 'Total Quantity',
            dataIndex: 'total_qty',
            key: 'total_qty',
            ...getColumnSearchProps('total_qty', 'decimal'),
        }, {
            title: 'Total Amount',
            dataIndex: 'total_amt',
            key: 'total_amt',
            ...getColumnSearchProps('total_amt', 'decimal'),
        }, {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button onClick={() => {
                        setSearchCountry(record.Import_Company);
                        setChartType('company');
                        setOpen(true);
                    }} icon={<BarChartOutlined />}></Button>
                </Space>
            ),
        }
    ];

    useEffect(() => {
        getList();
    }, [searchCondition]);

    const getList = async (e) => {
        if (Object.keys(searchCondition).length !== 0 && searchCondition.dateString.length > 0) {
            console.log('here', searchCondition);
            setProgress(true);
            try {
                const res = await fetch("/dataupload/GetSummaryByCompany/" + searchCondition.dateString[0] + "/" + searchCondition.dateString[1]);

                if (res.ok) {
                    setDataSource([]);
                    res.json().then(v => {
                        setDataSource(v);
                        setProgress(false);
                    }).then(() => {
                        console.log(dataSource);
                    });

                } else {
                    setProgress(false);
                    alert('Error occurred');
                }
            } catch (ex) {
                setProgress(false);
                console.log("error", ex.toString());
            } finally {
                console.log("test");
            }
        }
    }

    return (
        <>
            <Row style={{ marginTop: 30 + 'px' }}>
                <Col span={24}>
                    <h5 style={{ color: 'blue', fontWeight: 'bold' }}>Total sales by company {!_.isEmpty(searchCondition.dateString) ? searchCondition.dateString[0] + ' to ' + searchCondition.dateString[1] : ""}</h5>
                </Col>
            </Row>
            <Row style={{ marginTop: 30 + 'px' }}>
                <Col span={24}>
                    <Table dataSource={dataSource} columns={columns} loading={isProgress} />
                </Col>
            </Row>
        </>
    );
};